import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import LoaderScreen from '../components/LoaderScreen'

export default function Products() {

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 1000)

    return () => {
      cancel = true;
    } // eslint-disable-next-line
  }, []);

  if (loading === true) {
    return <LoaderScreen />
  }

  return (
    <Container maxWidth="lg">
      <Box mt={5}>
        <Typography mb={5} variant="h4">Balloting Services</Typography>
        <Typography mb={3} variant="h5">Terms of Service</Typography>
        <Typography sx={{ fontSize: '85%' }}>
          Introductory paragraph of terms:
          <Box display="inline"><Typography ml={-2} sx={{ fontSize: '85%' }}>
            <ol>
              <br />
              <li>term 1</li>
              <br />
              <li>term 2</li>
              <br />
              <li>term 3</li>
              <br />
            </ol>
          </Typography>
          </Box>
        </Typography>
        <Button color="inherit" variant="outlined" component={Link} to={"/"}>Home</Button>
      </Box>
    </Container>
  );
}