import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, responsiveFontSizes, ThemeProvider as MuiThemeProvider } from '@mui/material/styles'

const theme = responsiveFontSizes(createTheme({

  palette: {
    primary: {
      main: '#000000',
    },
    secondary: {
      main: '#ffffff',
    },
  },
  typography: {
    fontFamily: 'Helvetica, san-serif',
    h1: {
      fontFamily: 'Helvetica, san-serif',
      fontWeight: 'bold',
    },
    h2: {
      fontFamily: 'Helvetica, san-serif',
      fontWeight: '600',
    },
    h3: {
      fontFamily: 'Helvetica, san-serif',
      fontWeight: '500',
    },
    h4: {
      fontFamily: 'Helvetica, san-serif',
    },
    h5: {
      fontFamily: 'Helvetica, san-serif',
    },
    h6: {
      fontFamily: 'Helvetica, san-serif',
    },
    subtitle1: {
      fontFamily: 'Helvetica, san-serif',
      fontWeight: '500',
    },
    subtitle2: {
      fontFamily: 'Helvetica, san-serif',
    },
    body1: {
      fontFamily: 'Helvetica, san-serif',
    },
    body2: {
      fontFamily: 'Helvetica, san-serif',
      fontWeight: 'lighter',
    },
    button: {
      textTransform: 'none',
      fontFamily: 'Helvetica, san-serif',
      fontWeight: 'normal',
    },
    caption: {
      fontFamily: 'Helvetica Italic, san-serif',
    },
    overline: {
      fontFamily: 'Helvetica Italic, san-serif',
    },
    b: {
      fontFamily: 'Helvetica, san-serif',
      fontWeight: '500',
    }
  },
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&.MuiButton-outlined": {
            color: '#000000',
            border: '1px solid rgba(0, 0, 0, 0.3)',
            "&.Mui-disabled": {
              color: 'rgba(0, 0, 0, 0.3)',
              border: '1px solid rgba(0, 0, 0, 0.3)',
            },
          },
          "&.MuiButton-outlinedSuccess": {
            color: '#000000',
            border: '1px solid rgba(0, 0, 0, 0.3)',
            backgroundColor: '#F6FFF6',
            "&.Mui-disabled": {
              color: 'rgba(0, 0, 0, 0.3)',
              border: '1px solid rgba(0, 0, 0, 0.3)',
            },
          },
          "&.MuiButton-outlinedError": {
            color: '#000000',
            border: '1px solid rgba(0, 0, 0, 0.3)',
            backgroundColor: '#FFF6F6',
            "&.Mui-disabled": {
              color: 'rgba(0, 0, 0, 0.3)',
              border: '1px solid rgba(0, 0, 0, 0.3)',
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& .MuiInputLabel-root": {
            color: 'rgba(0, 0, 0, 0.8)',
            "&.Mui-disabled": {
              color: 'rgba(0, 0, 0, 0.3)',
            },
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: '5px !important',
        }
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          opacity: '0.1',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outline',
      },
      input: {
        color: 'black'
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: 'Helvetica, san-serif',
          fontSize: '0.75rem',
        }
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "2em",
        color: "black",
        backgroundColor: "white",
        border: '1px solid rgba(0, 0, 0, 0.3)',
        borderRadius: '5px'
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          justifyContent: 'center',
          alignItems: 'center',
          height: '85px',
          fontSize: '2.5rem',
          backgroundColor: '#EAEAEA',
          color: '#ffffff',
        },
      }
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          height: '70px',
          backgroundColor: '#000000',
          color: '#ffffff',
        },
      }
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          color: '#ffffff',
          backgroundColor: '#000000',
          "&.Mui-selected": {
            backgroundColor: '#212121',
            color: '#9F9F9F',
          },
        },
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          width: "100%"
        },
        accordion: {
          minHeight: 150,
          height: "100%"
        },
        details: {
          alignItems: "center",
          border: "1px solid rgba(0,0,0,0.1)",
          borderRadius: 4
        }
      }
    }
  },
}))

export default function ThemeProvider({ children }) {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MuiThemeProvider>
  )
}
