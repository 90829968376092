import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { initializeAppCheck, ReCaptchaEnterpriseProvider, getToken } from 'firebase/app-check'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyCZU9uYog8S4kBM3Gdzo_vYHwtPK-1zrSs",
  authDomain: "ivoter-2024.firebaseapp.com",
  databaseURL: "https://ivoter-2024-default-rtdb.firebaseio.com",
  projectId: "ivoter-2024",
  storageBucket: "ivoter-2024.appspot.com",
  messagingSenderId: "951683168877",
  appId: "1:951683168877:web:9b335d66c8c4f39fd41d64",
  measurementId: "G-1RBDE9GX66"
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const appCheck = initializeAppCheck(firebaseApp, {
  provider: new ReCaptchaEnterpriseProvider('6LdDGyQpAAAAAAXax4JANjwqbvwFTxr4m6nbFsSL'),
  isTokenAutoRefreshEnabled: true
});

getToken(appCheck)
  .then(() => {
    console.log('AppCheck token retrieved successfully.')
  })
  .catch((error) => {
    console.log(error.message)
  })

const auth = getAuth(firebaseApp);

export { auth, analytics };

export default firebaseApp;