import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import iVOTErLogo from '../images/iVOTEr.svg'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import LoaderScreen from '../components/LoaderScreen'
import { useAuth } from '../contexts/AuthContext'

export default function Landing() {

  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const { currentUser } = useAuth()



  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 1000)

    return () => {
      cancel = true;
    }
  }, [currentUser]);

  if (loading === true) {

    return <LoaderScreen />

  } else {

    return (
      <>
        <Container maxWidth="sm">
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box mt={20}>
              <Typography align="center"><img src={iVOTErLogo} alt="iVOTEr Logo" height="50" width="auto" align="center" /></Typography>
              <Typography align="center" mt={5} mb={2} variant="h5">Innovative, simple and secure enterprise agreement ballots.</Typography>
            </Box>
            <Box mt={2}>
              <Typography align="center"><Link align="center" href="mailto:ballots@hennings.com.au?subject=iVOTEr Ballot Inquiry">Contact us for more information or to request a ballot.</Link></Typography>
            </Box>
            <Box mt={2}>
              <Typography align="center"><Link align="center" href="/privacy">Privacy Policy</Link></Typography>
            </Box>
          </Grid>
        </Container>
      </>
    )
  }
}