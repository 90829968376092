import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import LoaderScreen from '../components/LoaderScreen'

export default function Home() {

  const [loading, setLoading] = useState(true);

  const Clock = () => {
    const [time, setTime] = useState(new Date());
    useEffect(() => {
      let TimeId = setInterval(() => setTime(new Date()), 1000);
      return () => {
        clearInterval(TimeId);
      };
    });

    return <div>{time.toLocaleTimeString('en-US', { timeZone: 'Australia/Brisbane' })}</div>
  };

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 1000)

    return () => {
      cancel = true;
    }
  }, []);

  if (loading === true) {

    return <LoaderScreen />

  } else {
    return (
      <>
        <Box mt={5}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography variant="h4" mb={5}>Home</Typography>
          </Grid>
          <Typography mt={5} variant="p">Welcome to iVOTEr.</Typography>
                    <Typography mt={5} variant="h4"><Clock /></Typography>
          <Box
            mb={3}
            component="span"
            display="flex"
            justifyContent="space-between"
            alignItems="right"
          >
            <a style={{ color: '#ffffff'}} href="https://hennings.com.au">Hennings Lawyers Pty Ltd t/as iVOTEr</a>
          </Box>
        </Box>
      </>
    );
  }
}