import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import iVOTErLogo from '../images/iVOTEr.svg'
import { useAuth } from '../contexts/AuthContext'

export default function TopBar() {

  const { currentUser, logout } = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)

  async function handleSubmitLogout() {
    try {
      await logout();
      navigate("/");
      localStorage.removeItem('activeTab')
      setLoading(false)
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 2000)

    return () => {
      cancel = true;
    } // eslint-disable-next-line
  }, []);

  if (loading === true) {

    return (
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Container maxWidth="xl">
            <Toolbar disableGutters>

            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    )

  } else {

    return (
      <Box>
        <AppBar position="static">
          <Container align="center" maxWidth="xl">
            <Toolbar disableGutters>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Grid align="left" item>
                  <Button variant="text" href={"/"}><img src={iVOTErLogo} alt="$" height="30" width="auto" /></Button>
                  <Button variant="text" href={"/createballot"}>Create</Button>
                  <Button variant="text" href={"/findballot"}>Find</Button>
                </Grid>
                <Grid align="right" item>
                  {currentUser ? (
                    <Button variant="text" onClick={handleSubmitLogout}>Logout</Button>
                  ) : (
                    <Button variant="text" href={"/"}>Login</Button>
                  )}
                </Grid>
              </Grid>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    );
  }
}