import React, { createContext, useContext, useState, useEffect } from 'react'
import { auth } from '../firebase'
import { linkWithCredential, EmailAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, updateEmail, updatePassword, deleteUser, onAuthStateChanged, signOut, setPersistence, browserLocalPersistence, updateProfile } from 'firebase/auth'

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState()
  const [bottomNav, setBottomNav] = useState("")
  const [loading, setLoading] = useState(true)

  const user_timezone = "Australia/Brisbane"

  const formatUser = async (user) => {
    const token = await user.getIdToken();
    return {
      uid: user.uid,
      providerData: user.providerData,
      firstName: '',
      lastName: '',
      address: '',
      token
    };
  };

  async function handleUser(rawUser) {
    if (rawUser) {
      const user = await formatUser(rawUser);
      setCurrentUser(user)

      return user
    } else {
      setCurrentUser(false)
      return false
    }
  }

  async function register(email, password) {
    const response = await createUserWithEmailAndPassword(auth, email, password)
    handleUser(response.user)
  }

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password)
  }

  async function linkEmailProvider(email, password) {
    const credential = EmailAuthProvider.credential(email, password)
    try {
      const usercred = await linkWithCredential(auth.currentUser, credential)
      const user_1 = usercred.user
      console.log("Link with Email successful", user_1)
    } catch (err) {
      console.log("This email could not be linked to your iVOTEr account.", err)
    }
  }

  async function updateAccountEmail(email) {
    return await updateEmail(currentUser, email);
  }

  async function updateAccountPassword(password) {
    return await updatePassword(currentUser, password);
  }

  function deleteAccount() {
    const user = auth.currentUser;
    return deleteUser(user)
  }

  async function logout() {
    try {
      await signOut(auth)
      console.log(currentUser.uid + " has now signed out of iVOTEr.")
    } catch (error) {
      console.log(error)
    }
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email)
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          setCurrentUser(user)
        } else {
          setCurrentUser(null);
        }
      } catch (error) {

      } finally {
        updateProfile(currentUser, {
          timezone: user_timezone
        })
        setLoading(false);
      }
    });

    return () => unsubscribe(); // eslint-disable-next-line
  }, []);

  const value = {
    loading,
    setLoading,
    linkEmailProvider,
    updateAccountEmail,
    updateAccountPassword,
    deleteAccount,
    bottomNav,
    setBottomNav,
    currentUser,
    register,
    handleUser,
    login,
    logout,
    resetPassword
  }

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  )
}