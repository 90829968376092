import React, { useEffect } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Container from '@mui/material/Container'
import TopBar from './components/TopBar'
import LoaderScreen from './components/LoaderScreen'
import Home from './pages/Home'
import Landing from './pages/Landing'
import Auth from './pages/Auth'
import About from './pages/About'
import Products from './pages/Products'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'
import NotFound from './pages/NotFound'
import { useAuth } from './contexts/AuthContext'

export default function App() {

  const { currentUser } = useAuth()

  useEffect(() => {

  }, [currentUser]);

  return (
    <>
      {currentUser && <TopBar />}
      <Container maxWidth="lg">
        <Container maxWidth="md">
          <Routes>
            {!currentUser && <Route exact path="/" element={<Landing />} />}
            {!currentUser && <Route exact path="/" element={<LoaderScreen />} />}
            {currentUser && <Route exact path="/" element={<Navigate to="/home" replace />} />}
            {currentUser && <Route path="/home" element={<Home />} />}
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<Products />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/auth" element={<Auth />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Container>
      </Container>
    </>
  )
}
