import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Container from '@mui/material/Container'
import LoaderScreen from '../components/LoaderScreen'

export default function Privacy() {

  const [loading, setLoading] = useState(true)

  const PDFViewer = () => {
    return (
      <div>
        <iframe src="iVOTEr_Privacy_Policy.pdf" width="100%" height="750px" />
      </div>
    );
  };

  useEffect(() => {
    let cancel = false;

    if (cancel) return;
    setTimeout(() => setLoading(false), 1000)

    return () => {
      cancel = true;
    } // eslint-disable-next-line
  }, []);

  if (loading === true) {
    return <LoaderScreen />
  }

  return (
    <Container maxWidth="lg">
      <PDFViewer />
      <Box mt={5}>
        <Button color="inherit" variant="outlined" component={Link} to={"/"}>Home</Button>
      </Box>
    </Container>
  );
}